.image-text-grid-block {
  .image-text-grid-container {
    .heading {
      color: var(--history-black);
    }
    .image-text-item {
      .details-container {
        color: var(--history-black);
      }
    }
  }
}