.home-intro-block {
  .background-image-container {
    .background-image {
      width: 630px;
      height: 95%;
      @include screen-md-max {
        width: 80%;
      }
    }
  }
  .home-intro-container {
    .heading-container {
      .heading {
        color: var(--history-black);
      }
    }
    .body {
      > * {
        color: var(--history-black);
      }
    }
  }
}