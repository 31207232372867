.benefits-block {
  .benefits-container {
    .benefits-section {
      .heading {
        color: var(--history-black);
      }
      .subheading {
        color: var(--deg-neutral-grey-2);
      }
      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        background: var(--history-black);
        .icon {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}