.project-block {
  .project-container {
    .media-container-1 {
      .wrapper {
        .text-container {
          color: var(--history-black);
        }
      }
    }
  }
}