.app.home {
  .app-header {
    background: transparent;
    &.active {
      background: var(--deg-white);
      .container {
        .section-top {
          .icon-hamburger-menu.mobile line {
            stroke: var(--history-black);
          }
          .logo-container {
            .app-logo-secondary {
              .logo-secondary {
                filter: invert(0);
              }
            }
          }
        }
        .section-bottom {
          .item {
            color: var(--history-black);
          }
        }
      }
    }
    &.scroll-down {
      background: var(--deg-white);
      .container {
        .section-top,
        .section-bottom {
          .item {
            color: var(--history-black);
          }
        }
        .section-top {
          .icon-hamburger-menu {
            line {
              stroke: var(--history-black);
            }
          }
        }
        .logo.logo-secondary {
          @include screen-md-max {
            filter: invert(0);
          }
        }
      }
    }
    .logo.logo-primary {
      filter: invert(1);
      .logo-primary {
        
      }
    }
    .logo.logo-secondary {
      @include screen-md-max {
        filter: invert(1);
      }
    }
    .icon-hamburger-menu {
      @include screen-lg-max {
        display: flex;
      }
      line {
        stroke: var(--history-white);
        transition: stroke 0.3s ease-in-out;
      }
    }
    .section-top,
    .section-bottom {
      .item {
        color: var(--history-white);
        transition: color 0.3s ease-in-out;
      }
    }
  }
}

.app {
  margin-top: 250px;
  transition: margin-top 0.3s;
  @include screen-md-max {
    margin-top: 76px;
  }
  &.home {
    margin-top: 0;
  }
  &.scroll-down {
    margin-top: 0;
  }
}