.text-listing-block {
  .text-listing-container {
    .title {
      color: var(--history-black);
    }
    .table-container {
      .row {
        .left {
          .row-title {
            color: var(--history-black);
          }
          .row-divider {
            background: var(--primary-colour);
          }
        }
        .right {
          .row-body {
            color: var(--deg-neutral-grey-2);
          }
        }
      }
    }
  }
}