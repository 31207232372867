.partial-button {
  align-self: center;
  background: var(--primary-colour);
  .text-container {
    .text {
      color: var(--deg-pure-black);
    }
    .arrow {
      line,
      polyline {
        stroke: var(--deg-pure-black);
      }
    }
  }
}