.horizontal-icon-tile-block {
  .horizontal-icon-tile-container {
    .desktop-tiles {
      .tile {
        .bottom {
          .bottom-wrapper {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: var(--history-black);
            overflow: hidden;
            .icon {
              width: 40px;
              height: 40px;
            }
            .body {
              color: var(--history-black);
            }
          }
        }
      }
    }
    .tile {
      .top {
        .heading {
          color: var(--history-black);
        }
      }
      .bottom {
        .bottom-wrapper {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: var(--history-black);
          overflow: hidden;
          .icon {
            width: 40px;
            height: 40px;
          }
          .body {
            color: var(--history-black);
          }
        }
      }
    }
  }
}