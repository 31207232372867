.locations-block {
  .container {
    .location-tiles-container {
      .tile {
        .details-container {
          color: var(--history-black);
          .details {
            .detail-block {
              a {
                color: var(--history-black);
              }
              .icon {
                svg {
                  path {
                    stroke: var(--history-black);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}