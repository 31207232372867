@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

h1,
h2,
h3,
h4 {
  font-family: var(--dm-serif);
  font-weight: 400;
}

h5,
h6,
p,
li,
p.small,
.tag.small,
ul,
ol,
li {
  font-family: var(--montserrat);
}

h1 {
  font-size: 80px;
  line-height: 88px;
  @include screen-md-max {
    font-size: 44px;
    line-height: 56px;
  }
}

h2 {
  font-size: 48px;
  line-height: 64px;
  @include screen-md-max {
    font-size: 34px;
    line-height: 42px;
  }
}

h3 {
  font-size: 32px;
  line-height: 44px;
  @include screen-md-max {
    font-size: 24px;
    line-height: 34px;
  }
}

h4 {
  font-size: 24px;
  line-height: 36px;
  @include screen-md-max {
    font-size: 20px;
    line-height: 30px;
  }
}

h5 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  @include screen-md-max {
    font-size: 18px;
    line-height: 28px;
  }
}

h6 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  @include screen-md-max {
    font-size: 14px;
    line-height: 24px;
  }
}

p {
  font-size: 18px;
  line-height: 28px;
  @include screen-md-max {
    font-size: 16px;
    line-height: 24px;
  }
}

li {
  font-size: 16px;
  line-height: 24px;
  @include screen-md-max {
    font-size: 14px;
    line-height: 20px;
  }
}

p.small {
  font-size: 16px;
  line-height: 24px;
  @include screen-md-max {
    font-size: 14px;
    line-height: 20px;
  }
}

.tag.small {
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 48px;
  padding: 5px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  @include screen-md-max {
    font-size: 14px;
    line-height: 20px;
  }
}

ul li, ol li {
  padding-left: 17px;
  margin-left: 18px;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 24px;
  @include screen-md-max {
    font-size: 14px;
    line-height: 20px;
  }
}

a {
  color: var(--primary-colour);
}