.app-footer {
  background: var(--history-black);
  * {
    font-family: var(--montserrat);
  }
  .container {
    .row-1 {
      .logo-tagline-container {
        .logo-container {
          img,
          svg {
            width: 45px;
            height: 41px;
          }
        }
      }
    }
  }
}