.logo-text-listing-block {
  .logo-text-listing-container {
    .title {
      color: var(--history-black);
    }
    .table-container {
      .row {
        .row-details {
          .left {
            .row-title {
              color: var(--history-black);
            }
          }
          .right {
            .row-body {
              color: var(--deg-neutral-grey-2);
            }
          }
        }
      }
    }
  }
}